import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { ErrorFeild, ErrorClass } from "../../services/Controller/ValidationForm";
import { Context as InscriptionRecruter } from "../../context/reducers/InscriptionReducer";
import { emailPattern, phonePattern, passwordPattern } from "../../services/Controller/PattenService";
import { onFocusPhoneNumber, onBlurPhoneNumber } from "../../services/Controller/PhoneService";
import AlertModal from '../../components/alert/alert.js';
import * as Yup from "yup";
import "./RecruterInscrit.scss";
import { trackPixelEvent } from "../MetaPixel.js";

/* validation Form step 1 */
const validationCompte = Yup.object({
  email: Yup.string()
    .required("Email obligatoire")
    .email("Email invalide")
    .matches(emailPattern, "Email invalide"),
  password: Yup.string()
    .min(8, "Mot de passe doit contenir au moins 8 caractères")
    .max(15, "Mot de passe doit être entre 8 et 15 caractères")
    .matches(passwordPattern, "Utiliser au moins 8 caractères avec des lettres, des chiffres et des symbôles")
    .required("Mot de passe obligatoire"),
  passwordconfim: Yup.string()
    .required("Confirmation mot de passe obligatoire ")
    .oneOf([Yup.ref("password"), null], "Mot de passe n'est pas identique"),
  companyname: Yup.string().required("Nom de l’entreprise obligatoire"),
  phone: Yup.string()
    .matches(phonePattern, "Numéro de téléphone doit commencer par +33 et ne doit contenir que des chiffres")
    .min(11, "Numéro de téléphone doit être entre 11 et 15 caractères")
    .max(15, "Mot de passe doit être entre 11 et 15 chiffres"),
  sector: Yup.string().required("Domaine d'activité obligatoire")
});

/* validation Form step 1 */
const validationProfile = Yup.object({
  firstname: Yup.string().required("Nom obligatoire"),
  lastname: Yup.string().required("Prénom obligatoire"),
  poste: Yup.string().required("Poste actuel obligatoire"),
  terms: Yup.boolean().oneOf([true], "Accepter les termes et les conditions"),
});

/* */
export default function RecruterInscrit() {
  /* Context Api*/
  const { inscriptionRecruter, getDomainRecruter, getListRegions, checkEmail, state } = useContext(InscriptionRecruter);

  /* all states */
  const [step, setstep] = useState(1);
  const [isActive, setActive] = useState("false");
  const [isClass, setClass] = useState("false");
  const [allDomains, setAllDomains] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [errorEmail, setErrorEmail] = useState("");

  /* show modal message */
  const [show, setShow] = useState(false);

  /*eslint-disable*/
  useEffect(() => {
    getDomainRecruter();
    getListRegions().then((res) => { setAllRegions(res.data) });
  }, []);
  /*eslint-enable*/

  /* get all domains */
  useEffect(() => {
    if (state.doaminRecruter) setAllDomains(state.doaminRecruter);
  }, [state.doaminRecruter]);

  /* Show & Hide Alert */
  useEffect(() => {
    if (state.toastContent) {
      if (state.toastContent.status === "success") setShow(true);
      else setShow(false);
    }
  }, [state.toastContent]);

  /* form step 1 */
  const CompteForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordconfim: "",
      companyname: "",
      sector: "",
      phone: "",
    },
    validationSchema: validationCompte,
    onSubmit: (values) => {
      const data = { email: values.email };
      checkEmail(data).then(
        (res) => {
          if (res.exist === "true") {
            setErrorEmail("Email existant");
          }
          else {
            setErrorEmail("");
            handleToggle();
            setstep(2);
            trackPixelEvent('InitiateRegistration', {
              company_name: values.companyname,
              sector: values.sector,
              has_phone: !!values.phone
            });
          }
        },
        (err) => {
          setErrorEmail("Email existant");
        })
    },
  });

  /* form step 2 */
  const FormProfil = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      poste: "",
      place: "",
      adress: "",
      company: "",
      link: "",
      terms: false,
    },
    validationSchema: validationProfile,
    // onSubmit: (values) => {
    //   handleClass();
    //   setstep(3);
    // },
    onSubmit: (values) => {
      const data = {
        email: CompteForm.values.email,
        password: CompteForm.values.password,
        companyName: CompteForm.values.companyname,
        idDomaineRecruiter: CompteForm.values.sector,
        phoneNumber: CompteForm.values.phone,
        firstname: FormProfil.values.firstname,
        lastname: FormProfil.values.lastname,
        poste: FormProfil.values.poste,
        country: FormProfil.values.place,
        address: FormProfil.values.adress,
        description: FormProfil.values.company,
        link: FormProfil.values.link,
        recruitEmployee: values.employee,
        selfEmployed: values.independance,
        independent: values.workindependance,
        likeIndependent: values.likedependance,
        remoteConsultant: values.remoteconsultant,
        expandTeam: values.teamengineer,
        newProject: values.newproject,
        hireIng: values.recruterengineer,
      };
      trackPixelEvent('Lead', {
        registration_type: 'recruiter',
        region: values.place,
        position: values.poste,
        has_website: !!values.link
      });
      handleClass();
      CompteForm.resetForm();
      FormProfil.resetForm();
      lookForm.resetForm();
      setstep(1);
      inscriptionRecruter(data);
      window.scrollTo(0, 0);
    },
  });

  /* form step 3 */
  const lookForm = useFormik({
    initialValues: {
      employee: false,
      independance: false,
      workindependance: false,
      likedependance: false,
      remoteconsultant: false,
      teamengineer: false,
      newproject: false,
      recruterengineer: false,
    },
    onSubmit: (values) => {
      const data = {
        email: CompteForm.values.email,
        password: CompteForm.values.password,
        companyName: CompteForm.values.companyname,
        idDomaineRecruiter: CompteForm.values.sector,
        phoneNumber: CompteForm.values.phone,
        firstname: FormProfil.values.firstname,
        lastname: FormProfil.values.lastname,
        poste: FormProfil.values.poste,
        country: FormProfil.values.place,
        address: FormProfil.values.adress,
        description: FormProfil.values.company,
        link: FormProfil.values.link,
        recruitEmployee: values.employee,
        selfEmployed: values.independance,
        independent: values.workindependance,
        likeIndependent: values.likedependance,
        remoteConsultant: values.remoteconsultant,
        expandTeam: values.teamengineer,
        newProject: values.newproject,
        hireIng: values.recruterengineer,
      };
      CompteForm.resetForm();
      FormProfil.resetForm();
      lookForm.resetForm();
      setstep(1);
      inscriptionRecruter(data);
      window.scrollTo(0, 0);
    },
  });

  /* Steps toggle & active style */
  const handleToggle = () => { setActive(!isActive); };
  const handleClass = () => { setClass(!isClass); };
  const updateSteps = (e) => {
    if (e.target.value === 1) setstep(1);
    else if (e.target.value === 2) CompteForm.handleSubmit();
    else if (e.target.value === 3) FormProfil.handleSubmit();
  };
  const Progress = () => {
    return (
      <React.Fragment>
        <ul id="progressbar">
          <li className="active roboto-light fontSize-25" onClick={(e) => { updateSteps(e); }} value={1}>
            Compte
          </li>
          <li onClick={(e) => { updateSteps(e); }} className={"active roboto-light fontSize-25"} value={2}>
            Votre Entreprise
          </li>
          {/* <li onClick={(e) => { updateSteps(e); }} className={isClass ? null : "active"} value={3}>
            Vous cherchez
          </li> */}
        </ul>
      </React.Fragment>
    );
  };

  /* fisrt step */
  function FirstStep() {
    return (
      <Form onSubmit={CompteForm.handleSubmit} className="pb-5">
        <Form.Group>
          <Form.Label className="required title-inscri">Email</Form.Label>
          <Form.Control
            className={ErrorClass(CompteForm, "email") || (errorEmail !== "" ? "error" : "")}
            type="email" name="email" id="email"
            placeholder="Saisir votre adresse email"
            onChange={CompteForm.handleChange}
            value={CompteForm.values.email}
            onBlur={CompteForm.handleBlur}
            onFocus={() => { setErrorEmail("") }}
          />
          {ErrorFeild(CompteForm, "email")}
          {errorEmail !== "" ? <p className="error_feild mt-1">{errorEmail}</p> : null}
        </Form.Group>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri">
                Mot de Passe
              </Form.Label>
              <Form.Control
                type="password" name="password" id="password"
                placeholder="Saisir votre mot de passe"
                className={ErrorClass(CompteForm, "password")}
                onChange={CompteForm.handleChange}
                value={CompteForm.values.password}
                onBlur={CompteForm.handleBlur}
              />
              {ErrorFeild(CompteForm, "password")}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label className="required title-inscri">
                Confirmer le mot de passe
              </Form.Label>
              <Form.Control
                type="password" name="passwordconfim" id="passwordconfim"
                placeholder="Confirmer votre mot de passe"
                className={ErrorClass(CompteForm, "passwordconfim")}
                onChange={CompteForm.handleChange}
                value={CompteForm.values.passwordconfim}
                onBlur={CompteForm.handleBlur}
              />
              {ErrorFeild(CompteForm, "passwordconfim")}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label className="required title-inscri">
            Nom de l’entreprise
          </Form.Label>
          <Form.Control
            type="text" name="companyname" id="companyname"
            placeholder="Saisir le nom de votre entreprise"
            className={ErrorClass(CompteForm, "companyname")}
            onChange={CompteForm.handleChange}
            value={CompteForm.values.firstname}
            onBlur={CompteForm.handleBlur}
          />
          {ErrorFeild(CompteForm, "companyname")}
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri required">
            Secteur d'activité
          </Form.Label>
          <Form.Control
            name="sector" id="sector" as="select"
            value={CompteForm.values.sector}
            onChange={CompteForm.handleChange}
            className={ErrorClass(CompteForm, "sector")}>
            <option disabled selected value="" className="text-bold">
              Sélectionner un domaine
            </option>
            {allDomains.map((opt, index) => {
              return (
                <option value={opt.id} key={"sector" + index}>
                  {opt.name}
                </option>
              );
            })}
          </Form.Control>
          {ErrorFeild(CompteForm, "sector")}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="title-inscri">
            Numéro de téléphone
          </Form.Label>
          <Form.Control type="text" id="phone" name="phone"
            placeholder="Saisir votre numéro de téléphone"
            className={ErrorClass(CompteForm, "phone")}
            onChange={CompteForm.handleChange}
            onBlur={() => { onBlurPhoneNumber(CompteForm, "phone") }}
            onFocus={() => { onFocusPhoneNumber(CompteForm, "phone") }}
            value={CompteForm.values.phone}
          />
          {ErrorFeild(CompteForm, "phone")}
        </Form.Group>
        <Button variant="primary" type="submit" className="float-right button-next">
          Suivant
        </Button>
      </Form>
    );
  }

  /* second step */
  function NextStep() {
    return (
      <Form onSubmit={FormProfil.handleSubmit} className="pb-5">
        <Row>
          <Col lg={6} md={12} xs={12}>
            <Form.Group>
              <Form.Label className="required title-inscri"> Nom </Form.Label>
              <Form.Control
                className={ErrorClass(FormProfil, "firstname")}
                type="text" name="firstname" id="firstname"
                placeholder="Saisir votre nom"
                onChange={FormProfil.handleChange}
                value={FormProfil.values.firstname}
                onBlur={FormProfil.handleBlur}
              />
              {ErrorFeild(FormProfil, "firstname")}
            </Form.Group>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <Form.Group>
              <Form.Label className="required title-inscri"> Prénom </Form.Label>
              <Form.Control
                className={ErrorClass(FormProfil, "lastname")}
                type="text" name="lastname" id="lastname"
                placeholder="Saisir votre prénom"
                onChange={FormProfil.handleChange}
                value={FormProfil.values.lastname}
                onBlur={FormProfil.handleBlur}
              />
              {ErrorFeild(FormProfil, "lastname")}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label className="required title-inscri"> Poste actuel </Form.Label>
          <Form.Select name="poste" id="poste" className={ErrorClass(FormProfil, "poste")}
            onChange={FormProfil.handleChange}
            onBlur={FormProfil.handleBlur}
            value={FormProfil.values.poste}
          >
            <option className="text-bold" disabled selected value=""> Sélectionner un poste </option>
            <option value="Directeur">Directeur</option>
            <option value="Manager">Manager</option>
            <option value="Chef du projet">Chef du projet</option>
            <option value="Responsable RH">Responsable RH</option>
            <option value="Directeur RH">Directeur RH</option>
            <option value="Responsable recrutement">Responsable recrutement</option>
            <option value="Assistant de direction">Assistant de direction</option>
            <option value="Ingénieur d'affaire">Ingénieur d'affaire</option>
            <option value="Autre">Autre</option>
          </Form.Select>
          {ErrorFeild(FormProfil, "poste")}
        </Form.Group>
        <Row>
          <Col lg={6} md={12} xs={12}>
            <Form.Group>
              <Form.Label className="title-inscri">Région</Form.Label>
              <Form.Select name="place" id="place"
                onChange={FormProfil.handleChange}
                onBlur={FormProfil.handleBlur}
                value={FormProfil.values.place}>
                <option className="text-bold" value={""}> Sélectionner une région </option>
                {
                  allRegions && allRegions.length
                    ? allRegions.map((el, index) => {
                      return (
                        <option key={"option" + index} value={el.nom}>
                          {el.nom}
                        </option>
                      )
                    })
                    : null
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label className="title-inscri"> Adresse </Form.Label>
              <Form.Control
                type="text" id="adress" name="adress"
                placeholder="Saisir votre adresse"
                className={ErrorClass(FormProfil, "adress")}
                onChange={FormProfil.handleChange}
                value={FormProfil.values.adress}
                onBlur={FormProfil.handleBlur}
              />
              {ErrorFeild(FormProfil, "adress")}
            </Form.Group>
          </Col>
        </Row>


        <Form.Group>
          <Form.Label className="title-inscri">
          A propos de votre besoin en formation
          </Form.Label>
          <Form.Control
            rows={4} as="textarea" id="company" name="company"
            placeholder="Saisir une description à propos de votre entreprise"
            className={ErrorClass(FormProfil, "company")}
            onChange={FormProfil.handleChange}
            value={FormProfil.values.company}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "company")}
        </Form.Group>
        <Form.Group>
          <Form.Label className="title-inscri"> Site Web Entreprise </Form.Label>
          <Form.Control type="text" id="link" name="link"
            placeholder="Saisir le site web de votre entreprise"
            className={ErrorClass(FormProfil, "link")}
            onChange={FormProfil.handleChange}
            value={FormProfil.values.link}
            onBlur={FormProfil.handleBlur}
          />
          {ErrorFeild(FormProfil, "link")}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <div className="d-flex justify-content-start">
            <Form.Check name="terms" type="checkbox"
              className={"title-Inscr pt-1 form-label" || ErrorClass(FormProfil, "terms")}
              onChange={FormProfil.handleChange}
              value={FormProfil.values.terms}
              onBlur={FormProfil.handleBlur}
            />
            <Form.Label className="title-inscri">
              <span className="ml-4">  Vous devez accepter les </span>
              <span onClick={() => window.open("/terms", "_blank")} className="conditions-text">
                termes et conditions
              </span>
            </Form.Label>
          </div>
          {ErrorFeild(FormProfil, "terms")}
        </Form.Group>
        <Button variant="primary" type="submit" className="float-right button-next">
         Envoyer
        </Button>
      </Form>
    );
  }

  /* last step */
  function EndStep() {
    return (
      <>
        <Form className="pb-5 p-4" onSubmit={lookForm.handleSubmit}>
          {/* work */}
          <div className="form-inscrit mb-3"> Vous cherchez </div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit pr-5">
              Vous cherchez à recruter un ou plusieurs salariés
            </div>
            <div>
              <Form.Check
                type="switch" id="employee" name="employee"
                onChange={lookForm.handleChange}
                value={lookForm.values.employee}
              />
            </div>
          </div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit">
              Vous cherchez un ou plusieurs indépendants
            </div>
            <div>
              <Form.Check
                type="switch" id="independance" name="independance"
                onChange={lookForm.handleChange}
                value={lookForm.values.independance}
              />
            </div>
          </div>
          {/* worked already */}
          <hr className="mb-4 mt-4"></hr>
          <div className="form-inscrit mb-3">Vous avez déjà travaillé avec</div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit">Indépendants</div>
            <div>
              <Form.Check
                type="switch" id="workindependance" name="workindependance"
                onChange={lookForm.handleChange}
                value={lookForm.values.workindependance}
              />
            </div>
          </div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit">Consultants à distance</div>
            <div>
              <Form.Check
                type="switch" id="remoteconsultant" name="remoteconsultant"
                onChange={lookForm.handleChange}
                value={lookForm.values.remoteconsultant}
              />
            </div>
          </div>
          {/* desire */}
          <hr className="mb-4 mt-4"></hr>
          <div className="form-inscrit mb-3">Vous souhaitez</div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit">
              Agrandir une équipe d'ingénieurs ou développer un projet déjà existant
            </div>
            <div>
              <Form.Check
                type="switch" id="teamengineer" name="teamengineer"
                onChange={lookForm.handleChange}
                value={lookForm.values.teamengineer}
              />
            </div>
          </div>
          <div className="d-flex d-flex justify-content-between">
            <div className="form-label-inscrit">Démarrer un nouveau projet</div>
            <div>
              <Form.Check
                type="switch" id="newproject" name="newproject"
                onChange={lookForm.handleChange}
                value={lookForm.values.newproject}
              />
            </div>
          </div>
          <div className="d-flex d-flex justify-content-between mb-5">
            <div className="form-label-inscrit">
              Faire votre première embauche d'un ingénieur
            </div>
            <div>
              <Form.Check
                type="switch" id="recruterengineer" name="recruterengineer"
                onChange={lookForm.handleChange}
                value={lookForm.values.recruterengineer}
              />
            </div>
          </div>
          <Button variant="primary" type="submit" className="float-right button-next">
            S'inscrire
          </Button>
        </Form>
      </>
    );
  }

  /* Steps display */
  switch (step) {
    case 1:
      return (
        <>
          {/* Alert */}
          <AlertModal show={show} setShow={setShow}
            content="Merci pour votre demande, votre compte est en attente d'activation de la part de l'administrateur de la plateforme" />
          <Progress />
          <div className="stepform">{FirstStep()}</div>
        </>
      );
    case 2:
      return (
        <>
          <Progress />
          <div className="stepform">{NextStep()}</div>
        </>
      );
    case 3:
      return (
        <>
          <Progress />
          <div className="stepform">{EndStep()}</div>
        </>
      );
    default:
      break;
  }
}

import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Row, Tabs, Tab, Table, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaCut, FaTrashAlt, FaPen } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";

import { Context as MissionContext } from "../../context/reducers/RecruiterReducer";
import { getStatusMission } from "../../services/Controller/MissionServices";
import { ModalMission } from "../../components/Mission/ModalMission.js";
// import AvatarGroup from "../../components/avatar/avatarGroup";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import ToastService from "../../components/Toast/ToastService";
import NothingToShow from "../../components/NothingToShow/NothingToShow";
import MissionsFilter from "../../components/MissionsFilter/MissionsFilter";
import Paginate from "../../components/Paginate/Paginate";
import "./FormationsDemandes.scss";
import AlertModal from "../../components/alert/alert";
import { format, set } from "date-fns";
export default function FormationsDemandes() {
  /* Context Api */
  const {
    state,
    getAllDemandedMissionsRecruiter,
    addMission,
    editMission,
    deleteMission,
    confirmMission,
    dumpMission,
    hideToast,
  } = useContext(MissionContext);
  /* use History */
  const history = useHistory();
  /* use Translation */
  const { t } = useTranslation();
  /* States */
  /* Modal Mission */
  const [modalShow, setModalShow] = useState(false);
  /* Modal Confirm */
  const [show, setShow] = useState(false);
  /* Mode Current Action */
  const [mode, setMode] = useState(false);
  /* seleted Mission */

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
const [showCompanyModal, setShowCompanyModal] = useState(false);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
    setShowCompanyModal(true);
  };

  useEffect(() => {
    console.log("selectedItem neeeeeeeeeeeeeeeeeed",selectedItem)
  }, [selectedItem]);


  /* Default Tab Value*/
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  /* List */
  const [allMissions, setAllMissions] = useState({
    All: [],
    proposed: [],
    ongoing: [],
    finished: [],
    interrupted: [],
  });
  /* Filter Mission */
  const [resetForm, setResetForm] = useState(false);
  /* Table Content */
  const userDisplayedColumns = [
    "Nom de la formation",
    "Nom du l'entreprise",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "",
  ];
  const RecruiterDisplayedColumn = [
    "Nom de la formation",
    "Nom du formateur",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "Contact",
    
  ];
  const displayedColumns = [
    "Nom de la formation",
    "Nom du formateur",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "Statut",
    
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const [modalType , setModalType] = useState("");

  useEffect(() => {
    console.log("modalType neeeeeeeeeeeeeeeeeed",modalType)
  }, [modalType])

  /* modal alert update and add mission message */
  const [showed, setShowed] = useState(false);
  const [showedMode, setShowedMode] = useState(false);
  const [filters, setFilters] = useState([]);

  /*eslint-disable */
  useEffect(() => {
    getAllDemandedMissionsRecruiter(filters);
  }, [filters]);
  /*eslint-enable */

  useEffect(() => {
    // if (state.allMissionRecruiter) {
    let list = state?.allDemandedMissionRecruiter?.All;
    // list?.map((el) => (el.missionTag = getStatusMission(el)));
    setAllMissions({
      All: list,
      // proposed: list.filter((el) => el.missionTag?.label === "Proposée"),
      // ongoing: list.filter((el) => el.missionTag?.label === "En Cours"),
      // finished: list.filter((el) => el.missionTag?.label === "Terminée"),
      // interrupted: list.filter(
      //   (el) => el.missionTag?.label === "Interrompue"
      // ),
    });
    setDisplayedData(list);
    // }
  }, [state]);
  /* refresh Proposed Mission */
  // useEffect(() => {
  //   if (state.mission) {
  //     getAllDemandedMissionsRecruiter();
  //     dumpMission();
  //   }
  // }, [dumpMission, getAllDemandedMissionsRecruiter, state.mission]);

  useEffect(() => {
    if (history.location.state) {
      setResetForm(true);
      setDisplayedData(allMissions[history.location.state]);
      setDefaultTabValue(history.location.state);
    }
  }, [
    setResetForm,
    setDisplayedData,
    setDefaultTabValue,
    history.location.state,
    allMissions,
  ]);

  /* alert add and update mission*/
  useEffect(() => {
    if (state.mission === "mission ajouté avec succes") {
      setShowed(true);
    } else if (state.mission?.message === "mission successfully edited") {
      setShowedMode(true);
    }
  }, [state.mission]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Content Missions List */

  // Header Cosmonaute
  function MissionCosmonauteHeader() {
    return (
      <Row className="mt-4">
        <Col lg={6} md={12} xs={12} className="pr-form mb-sm-3">
          <Card.Header>
            <div className="d-inline-text mr-20 current-mision-header roboto-light fontSize-25">
              Liste de formations demandées
            </div>
          </Card.Header>
        </Col>
        
      </Row>
    );
  }
  
  function showMissionsContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {(history.location.pathname === "/admin/formationsdemandees" 
        ? userDisplayedColumns 
        : history.location.pathname === "/user/formationsdemandees" 
            ? userDisplayedColumns 
            : userDisplayedColumns
              ).map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getListMissions() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* redirectTo Mission Detail */
  function redirectTo(el) {
    history.push(
      t("role.recruiter") + t("sidebar.missions.path") + "/" + el.id
    );
  }
  // Function that remove the p tag
  function stripHTMLTags(html) {
    return html?.replace(/<[^>]+>/g, "");
  }
  /* List Missions Mapping */
  function getListMissions() {
    return currentItems.map((el, index) => {
 return (
        <tr className="table-content table-padding" key={"Mission" + index}>
          {/* {history.location.pathname === '/admin/formationsproposes' ?  <td className="w-90">
            <div className="title-mission mb-2">
              <label className={"text-tag "}>{el.user}</label>
            </div>
          </td> : null } */}
          

          <td className="w-200">
            <div className="mb-2">
              {el.titre ? el.titre : null}
            </div>
          </td>
          <td className="w-200">
            <div
              className="width-right-cell"
              onClick={() => handleCompanyClick(el)}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }} // Style optionnel
            >
              {el.entreprise ? el.entreprise : null}
            </div>
          </td>
          <td className="w-200">
            <div className="width-right-cell">
              <label className={"text-tag "}>{el.typeName}</label>
            </div>
          </td>
          <td className="w-200">
            <div className="mb-2">
              {el.expertiseName ? el.expertiseName : null}
            </div>
          </td>
          <td className="w-150">
            <div className="mb-2">
              <p>{el.place ? el.place : null}</p>
            </div>
          </td>
          <td className="w-90">
            <div className="mb-2">
              <p>{el.Mode ? el.Mode : null}</p>
            </div>
          </td>
          <td className="w-200">
            <div className="mb-2">
              <label className={"text-tag "}>{el.nbrParticipants}</label>
            </div>
          </td>
          <td className="w-250">
            <div className="mb-2">
              <label className={"text-tag "}>
                {stripHTMLTags(el.description)}
              </label>
            </div>
          </td>
         
        </tr>
      );
    });
  }

  /* Select item */
  function onSelectItem(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    if (type !== "Edit") setShow(true);
    else setModalShow(true);
  }
  /* change status */
  function onSelectItemToChange(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    if (type !== "Edit") setShow(true);
    else setModalShow(true);
  }

  /* Delete | Interrupt Mission */
  function confirmAction() {
    if (mode === "Confirm" || mode === "Cancel") confirmMission(selectedItem.id,reponsedddd);
    else if (mode === "Delete") deleteMission(selectedItem.id);
  }

  /* Tabulation onChange */
  function changeTabulation(tab) {
    setResetForm(true);
    if (allMissions[tab] && allMissions[tab].length)
      setDisplayedData(allMissions[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }

  const [reponsedddd, setReponse] = useState();
  useEffect (() => {
    console.log("reponse neeeeeeeeeeeeeeed",reponsedddd)
  }, [reponsedddd])

  const handleTrue = () => {
    setReponse(true); 
  };

  const handleFalse = () => {
    setReponse(false); 
  }

  return (
    <>

<Modal
        show={showCompanyModal}
        onHide={() => setShowCompanyModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0 text-black">
          <Modal.Title id="contained-modal-title-vcenter">
            Détails de l'entreprise
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 text-black">
          {selectedCompany && (
            <>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Nom de l'entreprise :&nbsp;
                </span>
                {selectedCompany?.entreprise}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Email :
                </span>
                &nbsp;
                {selectedCompany?.mail}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Téléphone :
                </span>
                &nbsp;
                {selectedCompany?.phone}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Poste :
                </span>
                &nbsp;
                {selectedCompany?.poste}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Toast */}
      <ToastService data={state.toastContent} />
      <AlertModal
        show={showedMode}
        setShow={setShowedMode}
        content={"Mission éditée avec succès"}
      />
      <AlertModal
        show={showed}
        setShow={setShowed}
        content={"Mission ajoutée avec succès"}
      />
      {/* Confirm Modal */}
      
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={
          "Vous êtes sûr de vouloir " +
          (mode === "Confirm" ? "accepter" : mode === "Cancel" ? "refuser" : "supprimer") +
          " cette formation ?"
        }
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
        setReponse={setReponse}
        modalType={modalType}
        handleTrue={handleTrue}
        handleFalse={handleFalse}
      />
      {/* Mission Modal */}
      <ModalMission
        modalShow={modalShow}
        setModalShow={setModalShow}
        edit={selectedItem}
        setEdit={setSelectedItem}
        addMission={addMission}
        editMission={editMission}
      />
      {/* Mission Content */}
      <div id="header-Content" className="header-Content">
        {MissionCosmonauteHeader()}
      </div>
      <div id="main-without-header" className="main-without-header">
        {/* <Row className="align-md box-d-flex-order">
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <label className="title-page">Mes Missions et Formations</label>
          </Col>
          <Col lg={8} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
            />
          </Col>
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <Button
              className="float-right save-button green shadow-unset"
              onClick={() => {
                setModalShow(true);
              }}
            >
              Nouvelle mission
            </Button>
          </Col>
        </Row> */}
        <Col lg={9} md={12} xs={12} className="w-md-100 filter-interview-page">

            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
              postData = {getAllDemandedMissionsRecruiter}
              setFilters = {setFilters}
            />
          </Col>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="All"
            title={
              "Tous (" +
              (allMissions && allMissions.All ? allMissions.All.length : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          
          {/* <Tab
            eventKey="proposed"
            title={
              "Proposées (" +
              (allMissions && allMissions.proposed
                ? allMissions.proposed.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="ongoing"
            title={
              "En cours (" +
              (allMissions && allMissions.ongoing
                ? allMissions.ongoing.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="finished"
            title={
              "Terminées (" +
              (allMissions && allMissions.finished
                ? allMissions.finished.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="interrupted"
            title={
              "Interrompues (" +
              (allMissions && allMissions.interrupted
                ? allMissions.interrupted.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Row, Tabs, Tab, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaCut, FaTrashAlt, FaPen } from "react-icons/fa";
import { Context as MissionContext } from "../../../context/reducers/RecruiterReducer";
import { getStatusMission } from "../../../services/Controller/MissionServices";
import { ModalMission } from "../../../components/Mission/ModalMission.js";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import MissionsFilter from "../../../components/MissionsFilter/MissionsFilter";
import Paginate from "../../../components/Paginate/Paginate";
import "./MissionRecruiter.scss";
import AlertModal from "../../../components/alert/alert";
import { format } from "date-fns";
export default function MissionRecruiter() {
  /* Context Api */
  const {
    state,
    getAllMissionsRecruiter,
    addMission,
    editMission,
    deleteMission,
    interruptMission,
    dumpMission,
    hideToast,
  } = useContext(MissionContext);
  /* use History */
  const history = useHistory();
  /* use Translation */
  const { t } = useTranslation();
  /* States */
  /* Modal Mission */
  const [modalShow, setModalShow] = useState(false);
  /* Modal Confirm */
  const [show, setShow] = useState(false);
  /* Mode Current Action */
  const [mode, setMode] = useState(false);
  /* seleted Mission */
  const [selectedItem, setSelectedItem] = useState(null);
  /* Default Tab Value*/
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  /* List */
  const [allMissions, setAllMissions] = useState({
    All: [],
    proposed: [],
    ongoing: [],
    finished: [],
    interrupted: [],
  });
  /* Filter Mission */
  const [resetForm, setResetForm] = useState(false);
  const [filters, setFilters] = useState([]);

  /* Table Content */
  const displayedColumns = [
    "Nom de la formation",
    "Date de début",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Catégorie de formation",
    "Nombre de participants ",
    "Description",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  /* modal alert update and add mission message */
  const [showed, setShowed] = useState(false);
  const [showedMode, setShowedMode] = useState(false);
  /*eslint-disable */
  useEffect(() => {
    getAllMissionsRecruiter(filters);
  }, [filters]);
  /*eslint-enable */

  useEffect(() => {
    if (state.allMissionRecruiter) {
      let list = state.allMissionRecruiter;
      list.map((el) => (el.missionTag = getStatusMission(el)));
      setAllMissions({
        All: list,
        proposed: list.filter((el) => el.missionTag?.label === "Proposée"),
        ongoing: list.filter((el) => el.missionTag?.label === "En Cours"),
        finished: list.filter((el) => el.missionTag?.label === "Terminée"),
        interrupted: list.filter(
          (el) => el.missionTag?.label === "Interrompue"
        ),
      });
      setDisplayedData(list);
    }
  }, [state]);
  /* refresh Proposed Mission */
  useEffect(() => {
    if (state.mission) {
      getAllMissionsRecruiter();
      dumpMission();
    }
  }, [dumpMission, getAllMissionsRecruiter, state.mission]);

  useEffect(() => {
    if (history.location.state) {
      setResetForm(true);
      setDisplayedData(allMissions[history.location.state]);
      setDefaultTabValue(history.location.state);
    }
  }, [
    setResetForm,
    setDisplayedData,
    setDefaultTabValue,
    history.location.state,
    allMissions,
  ]);

  /* alert add and update mission*/
  useEffect(() => {
    if (state.mission === "mission ajouté avec succes") {
      setShowed(true);
    } else if (state.mission?.message === "mission successfully edited") {
      setShowedMode(true);
    }
  }, [state.mission]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Content Missions List */
  function showMissionsContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getListMissions() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* redirectTo Mission Detail */
  function redirectTo(el) {
    history.push(
      t("role.recruiter") + t("sidebar.missions.path") + "/" + el.id
    );
  }
  // Function that remove the p tag
  function stripHTMLTags(html) {
    return html.replace(/<[^>]+>/g, "");
  }
  /* List Missions Mapping */
  function getListMissions() {
    return currentItems.map((el, index) => {

      return (
        <tr className="table-content table-padding" key={"Mission" + index}>
          <td className="w-250">
            <div>
              <div
                className="title-mission mb-2"
                //  onClick={() =>
                //  { redirectTo(el) }}
              >
                {el?.nomFormation ? el?.nomFormation : null}
              </div>
              {/* <div id="tag" className="text-tag blue ">
                                    <span className="width-right-cell">
                                        {el && el.profils ? el.profils.length : 0} profiles proposés
                                    </span>
                                </div> */}
            </div>
          </td>
          <td className="w-150">
            <div className="width-right-cell">
              {el.createdAt
                ? format(new Date(el.createdAt), "dd/MM/yyyy")
                : null}
            </div>
          </td>
          <td className="w-150">
            <div>
              <div className="title-mission mb-2">
                {el.expertiseName ? el.expertiseName : null}
              </div>
            </div>
          </td>
          <td className="w-90">
            <p>{el.place ? el.place : null}</p>
          </td>
          <td className="w-90">
            <p>{el.mode ? el.mode : null}</p>
          </td>
          <td className="w-150">
            <label className={"text-tag "}>{el.typeName}</label>
          </td>
          <td className="w-150">
            <label className={"text-tag "}>{el.nbrParticipants}</label>
          </td>
          <td className="w-150">
            <label className={"text-tag "}>
              {stripHTMLTags(el.description)}
            </label>
          </td>
          <td className="w-350">
            <Row className="text-align-last-right">
              {/* Interrupt Mission */}
              {el.missionTag?.label === "En Cours" ? (
                <Col lg={12} md={12} xs={12}>
                  <span
                    className="btn-action grey pointer"
                    onClick={() => {
                      onSelectItem("Interrupt", el);
                    }}
                  >
                    <FaCut className="mr-2 mb-1" /> Interrompre
                  </span>
                </Col>
              ) : null}
              {/* Delete | Edit Mission */}
              {el.missionTag?.label === "Proposée" ? (
                <>
                  <Col lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      onClick={() => {
                        onSelectItem("Edit", el);
                      }}
                    >
                      <FaPen className="mr-2 mb-1" /> Modifier
                    </span>
                  </Col>
                  <Col lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      onClick={() => {
                        onSelectItem("Delete", el);
                      }}
                    >
                      <FaTrashAlt className="mb-1" /> Supprimer
                    </span>
                  </Col>
                </>
              ) : null}
            </Row>
          </td>
        </tr>
      );
    });
  }

  /* Select item */
  function onSelectItem(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    if (type !== "Edit") setShow(true);
    else setModalShow(true);
  }

  /* Delete | Interrupt Mission */
  function confirmAction() {
    if (mode === "Interrupt") interruptMission(selectedItem.id);
    else if (mode === "Delete") deleteMission(selectedItem.id);
  }

  /* Tabulation onChange */
  function changeTabulation(tab) {
    setResetForm(true);
    if (allMissions[tab] && allMissions[tab].length)
      setDisplayedData(allMissions[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      <AlertModal
        show={showedMode}
        setShow={setShowedMode}
        content={"Mission éditée avec succès"}
      />
      <AlertModal
        show={showed}
        setShow={setShowed}
        content={"Mission ajoutée avec succès"}
      />
      {/* Confrm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={
          "Vous êtes sûr de vouloir " +
          (mode === "Interrupt" ? "interrompre" : "supprimer") +
          " cette mission ?"
        }
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
      />
      {/* Mission Modal */}
      <ModalMission
        modalShow={modalShow}
        setModalShow={setModalShow}
        edit={selectedItem}
        setEdit={setSelectedItem}
        addMission={addMission}
        editMission={editMission}
      />
      {/* Mission Content */}
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md box-d-flex-order">
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <label className="title-page">Mes Missions et Formations</label>
          </Col>
          <Col lg={8} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
              setFilters={setFilters}
            />
          </Col>
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <Button
              className="float-right save-button green shadow-unset"
              onClick={() => {
                setModalShow(true);
              }}
            >
              Nouvelle mission
            </Button>
          </Col>
        </Row>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="All"
            title={
              "Tous (" +
              (allMissions && allMissions.All ? allMissions.All.length : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          {/* <Tab
            eventKey="proposed"
            title={
              "Proposées (" +
              (allMissions && allMissions.proposed
                ? allMissions.proposed.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="ongoing"
            title={
              "En cours (" +
              (allMissions && allMissions.ongoing
                ? allMissions.ongoing.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="finished"
            title={
              "Terminées (" +
              (allMissions && allMissions.finished
                ? allMissions.finished.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="interrupted"
            title={
              "Interrompues (" +
              (allMissions && allMissions.interrupted
                ? allMissions.interrupted.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab> */}
        </Tabs>
        
      </div>
    </>
  );
}

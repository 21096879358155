import React, { useContext, useEffect, useState } from "react";
import "./InspirnautesAdmin.scss";
import { Row, Col, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import ModalDetail from "../../../components/ModalDetail/ModalDetail";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import { formatDate } from "../../../services/Controller/DateFormatter";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paginate from "../../../components/Paginate/Paginate";
import InspirnauteFilter from "../../../components/InspirnauteFilter/InspirnauteFilter";

/* Inspirnautes Profils Page */
function InspirnautesAdmin() {
  /* History */
  const history = useHistory();
  localStorage.setItem('m', 'k');
  /* use Translation */
  const { t } = useTranslation();
  /* Context API */
  const { getAllInspirnautes, hideToast, state } =
    useContext(AdministratorContext);

  /* inspirnautes lists */
  const [allInspirnautes, setAllInspirnautes] = useState([]);

  /* Table Content */
  const displayedColumns = [
    "Nom & Prénom Formateur",
    "Profil",
    "Email",
    "Téléphone",
    "Lieu",
    "Domaine d'expertise",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  /* filter Input */
  const [resetForm, setResetForm] = useState(false);
  /* Detail Profil */
  const [modalShow, setModalShow] = useState(false);
  const [DataToShow, setDataToShow] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    getAllInspirnautes();
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.allInspirnautes) {
      setAllInspirnautes(state.allInspirnautes);
      setDisplayedData(state.allInspirnautes);
    }
  }, [state]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Show Profil Detail */
  function functionshowProfileDetail(item) {
    setDataToShow({
      Title: "Détail Profil Formateur",
      data: [
        {
          title: "Nom Formateur",
          value: item.username ? item.username : null,
        },
        { title: "Profil", value: item._profil.poste ? item._profil.poste : null },
        // {
        //   title: "Nb années experiences",
        //   value: item.nbr_annee_exp ? item.nbr_annee_exp : null,
        // },
        { title: "Email", value: item.email ? item.email : null },
        { title: "Téléphone", value: item._profil.phone ? item._profil.phone : null },
        { title: "Lieu", value: item._profil.country ? item._profil.country : null },
        { title: "Domaine d'expertise", value: item._profil.last_reference ? item._profil.last_reference : null },
        // {
        //   title: "Disponibilité",
        //   value:
        //     item.dispo_start && item.dispo_end
        //       ? "Commence à " +
        //         item.dispo_start +
        //         " et termine à " +
        //         item.dispo_end
        //       : null,
        // },
        // {
        //   title: "Experiences",
        //   value:
        //     item.work_experiences && item.work_experiences.length
        //       ? item.work_experiences
        //           .map(
        //             (el) =>
        //               el.poste +
        //               " chez " +
        //               el.entreprise +
        //               " : de " +
        //               formatDate(el.from_date) +
        //               " à " +
        //               formatDate(el.to_date)
        //           )
        //           .join(", ")
        //       : null,
        // },
        // {
        //   title: "Langues",
        //   value:
        //     item.langues ? item.langues
        //       : null,
        // },
        // {
        //   title: "Description",
        //   value: item.description ? item.description : null,
        // },
        // {
        //   title: "Tarif",
        //   value: item.tarif ? item.tarif + " €" : null,
        // },
        // {
        //   title: "Compétences",
        //   value: item.skills ? item.skills 
        //   : null,
        // },
      ],
    });
    setModalShow(true);
  }

  /* Content Profils List */
  function showProfilesContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length
              ? getListInspirnautes()
              : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* list Inspirnautes Map */
  function getListInspirnautes() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Inspirnaute" + index}>
          <td className="w-350">
            {/* <p
              className="link"
              onClick={() => {
                redirectTo(el);
              }}
            > */}
              {el.username ? el.username : null}
            {/* </p> */}
          </td>
          <td className="w-200">
            <p>{el._profil.poste ? el._profil.poste : null}</p>
          </td>
          <td className="w-250">
            <p>{el.email ? el.email : null}</p>
          </td>

          <td className="w-250">
            <p>{el._profil.phone ? el._profil.phone : null}</p>
          </td>
          
          <td className="w-250">
            <p>{el._profil.country ? el._profil.country : null}</p>
          </td>
          <td className="w-250">
            <p>{el._profil.last_reference ? el._profil.last_reference : null}</p>
          </td>
          {/* <td className="w-400">
            <p>{el.last_reference ? el.last_reference : null}</p>
          </td> */}
          <td>
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip id="Consult" place="bottom">
                {" "}
                Consulter{" "}
              </ReactTooltip>
              <span
                className="btn-detail blue"
                onClick={() => {
                  functionshowProfileDetail(el);
                }}
              >
                <FaEye data-tip data-for="Consult" />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  /* redirectTo Formateur Detail */
  function redirectTo(el) {
    history.push(
      t("role.administrator") +
        t("sidebar.inspirnautes.path") +
        "/" +
        el.id_user
    );
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      {/*  Déatil Inspirnaute*/}
      <ModalDetail
        modalShow={modalShow}
        setModalShow={setModalShow}
        DataToShow={DataToShow}
      />
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md">
          <Col lg={3} md={12} xs={12}>
            <label className="title-page mb-4">Formateurs ({displayedData && displayedData.length?  displayedData.length: "0"})</label>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <InspirnauteFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              allMissions={allInspirnautes}
              setDisplayedData={setDisplayedData}
            />
          </Col>
        </Row>
        {showProfilesContent()}
      </div>
    </>
  );
}

/* Export inspirnautes list component */
export default InspirnautesAdmin;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import { Instagram, Facebook, Youtube } from "lucide-react";
import "./Apropos.scss";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import { Link } from "react-router-dom";
import imgOneAbout from "../../assets/img/5.png";
import img7 from "../../assets/img/6.png";
import img8 from "../../assets/img/temple.png";
import img9 from "../../assets/img/4.png";

export default function Apropos() {
  const { t } = useTranslation();

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <Header></Header>
      <section className="pg-apropos-sec1">
        <div className="title-container">
          <p className="slogan-talent text-uppercase text-center yellow-color">
            ACROPOLIA
          </p>
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          ACROPOLIA est la vision de 4 entrepreneurs réveurs qui souhaitent créer un monde où
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          chacun peut libérer son potentiel et contribuer à un avenir meilleur.
        </div>
        <img src={imgOneAbout} alt="cristals" className="imgOneAbout" />
      </section>

      <section className="pg-apropos-sec2">
        <div className="overlay-section2"></div>
        <div>
          <p className="text-center roboto-condensed-bold fontSize-35 yellow-color text-uppercase">
            NOTRE AMBITION
          </p>
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Favoriser l'accès à l'éducation et à un système de formation de qualité et ludique
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Accompagner chacun dans un objectif de développement personnel et professionnel
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Offrir des programmes de formation et de coaching adaptés aux besoins de chacun
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Créer un environnement d'apprentissage stimulant et inspirant
        </div>
        <img alt="overlay" src={img7} className="imgOne" />
      </section>

      <section className="pg-apropos-sec3">
        <div className="title-container">
          <div className="text-uppercase roboto-condensed-bold yellow-color title-sectionT">
            ACROPOLIA PLACE L'HUMAIN AU CŒUR DE SES PRÉOCCUPATIONS
          </div>
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Notre mission est de révéler le plein potentiel de chaque individu. Nous croyons fermement que chaque 
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          personne possède des talents uniques qui méritent d'être développés et valorisés. 
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Nous aidons nos formateurs à partager leurs connaissances et leur expertise grâce à des outils et
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          des méthodes pédagogiques innovantes. Nous leur offrons une plateforme pour s'épanouir et se développer.
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Notre approche humaine et bienveillante permet à chacun de progresser à son rythme et d'atteindre ses objectifs.
        </div>
        
        <img src={img9} alt="img9" className="imgNine" />
        <img src={img8} alt="img8" className="imgEight" />
        
      </section>

      <Footer />
    </div>
  );
}

function Footer() {
  const iconStyle = {
    color: '#FFD700',
    size: 24
  };

  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Acropolia Talents</h4>
            
            <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a 
                href="https://www.instagram.com/acropolia_academy/" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Instagram color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=61551011631802" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Facebook color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.youtube.com/@acropolia_academy" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Youtube color={iconStyle.color} size={iconStyle.size} />
              </a>
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
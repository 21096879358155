import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Row,
  Tabs,
  Tab,
  Table,
  Card,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaCut, FaTrashAlt, FaPen } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";

import { Context as MissionContext } from "../../context/reducers/RecruiterReducer";
import { getStatusMission } from "../../services/Controller/MissionServices";
import { ModalMission } from "../../components/Mission/ModalMission.js";
// import AvatarGroup from "../../components/avatar/avatarGroup";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import ToastService from "../../components/Toast/ToastService";
import NothingToShow from "../../components/NothingToShow/NothingToShow";
import MissionsFilter from "../../components/MissionsFilter/MissionsFilter";
import Paginate from "../../components/Paginate/Paginate";
import "./FormationsProposes.scss";
import AlertModal from "../../components/alert/alert";
import { format, set } from "date-fns";
import { red } from "@mui/material/colors";

export default function FormationsProposes() {
  /* Context Api */
  const {
    state,
    getAllProposedMissionsRecruiter,
    addMission,
    editMission,
    deleteMission,
    confirmMission,
    dumpMission,
    hideToast,
  } = useContext(MissionContext);
  /* use History */
  const history = useHistory();
  /* use Translation */
  const { t } = useTranslation();
  /* States */
  /* Modal Mission */
  const [modalShow, setModalShow] = useState(false);
  /* Modal Confirm */
  const [show, setShow] = useState(false);
  /* Mode Current Action */
  const [mode, setMode] = useState(false);
  /* seleted Mission */
  const [selectedItem, setSelectedItem] = useState(null);
  const [showTrainerModal, setShowTrainerModal] = useState(false);

  useEffect(() => {
    console.log("selectedItem neeeeeeeeeeeeeeeeeed", selectedItem);
  }, [selectedItem]);

  /* Default Tab Value*/
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  /* List */
  const [allMissions, setAllMissions] = useState({
    All: [],
    proposed: [],
    ongoing: [],
    finished: [],
    interrupted: [],
  });
  /* Filter Mission */
  const [resetForm, setResetForm] = useState(false);
  /* Table Content */
  const userDisplayedColumns = [
    "Nom de la formation",
    "Nom du formateur",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "Action",
    "Statut",
  ];
  const RecruiterDisplayedColumn = [
    "Nom de la formation",
    "Nom du formateur",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "Contact",
    "Statut",
  ];
  const displayedColumns = [
    "Nom de la formation",
    "Nom du formateur",
    "Catégorie de formation",
    "Domaine d’expertise",
    "Région ",
    "Mode",
    "Nombre de participants ",
    "Description",
    "Action",
    "Statut",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const [modalType, setModalType] = useState("");

  const [selectedTrainer, setSelectedTrainer] = useState(null);
  console.log("selectedTrainer", selectedTrainer);
  const handleTrainerClick = (trainer) => {
    setSelectedTrainer(trainer);
    setShowTrainerModal(true);
  };
  useEffect(() => {
    console.log("modalType neeeeeeeeeeeeeeeeeed", modalType);
  }, [modalType]);

  /* modal alert update and add mission message */
  const [showed, setShowed] = useState(false);
  const [showedMode, setShowedMode] = useState(false);
  const [filters, setFilters] = useState([]);

  /*eslint-disable */
  useEffect(() => {
    getAllProposedMissionsRecruiter(filters);
  }, [filters]);
  /*eslint-enable */

  useEffect(() => {
    // if (state.allMissionRecruiter) {
    let list = state?.allProposedMissionRecruiter?.All;
    list?.map((el) => (el.missionTag = getStatusMission(el)));
    setAllMissions({
      All: list,
      // proposed: list.filter((el) => el.missionTag?.label === "Proposée"),
      // ongoing: list.filter((el) => el.missionTag?.label === "En Cours"),
      // finished: list.filter((el) => el.missionTag?.label === "Terminée"),
      // interrupted: list.filter(
      //   (el) => el.missionTag?.label === "Interrompue"
      // ),
    });
    setDisplayedData(list);
    // }
  }, [state]);
  /* refresh Proposed Mission */
  // useEffect(() => {
  //   if (state.mission) {
  //     getAllProposedMissionsRecruiter();
  //     dumpMission();
  //   }
  // }, [dumpMission, getAllProposedMissionsRecruiter, state.mission]);

  useEffect(() => {
    if (history.location.state) {
      setResetForm(true);
      setDisplayedData(allMissions[history.location.state]);
      setDefaultTabValue(history.location.state);
    }
  }, [
    setResetForm,
    setDisplayedData,
    setDefaultTabValue,
    history.location.state,
    allMissions,
  ]);

  /* alert add and update mission*/
  useEffect(() => {
    if (state.mission === "mission ajouté avec succes") {
      setShowed(true);
    } else if (state.mission?.message === "mission successfully edited") {
      setShowedMode(true);
    }
  }, [state.mission]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Content Missions List */

  // Header Cosmonaute
  function MissionCosmonauteHeader() {
    return (
      <Row className="mt-4">
        <Col lg={6} md={12} xs={12} className="pr-form mb-sm-3">
          <Card.Header>
            <div className="d-inline-text mr-20 current-mision-header roboto-light fontSize-25">
              Liste de formations proposées
            </div>
          </Card.Header>
        </Col>
      </Row>
    );
  }

  function showMissionsContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {(history.location.pathname === "/admin/formationsproposes"
                ? displayedColumns
                : history.location.pathname === "/user/formationsproposes"
                ? userDisplayedColumns
                : RecruiterDisplayedColumn
              ).map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getListMissions() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* redirectTo Mission Detail */
  function redirectTo(el) {
    history.push(
      t("role.recruiter") + t("sidebar.missions.path") + "/" + el.id
    );
  }
  // Function that remove the p tag
  function stripHTMLTags(html) {
    return html?.replace(/<[^>]+>/g, "");
  }
  /* List Missions Mapping */
  function getListMissions() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Mission" + index}>
          {/* {history.location.pathname === '/admin/formationsproposes' ?  <td className="w-90">
            <div className="title-mission mb-2">
              <label className={"text-tag "}>{el.user}</label>
            </div>
          </td> : null } */}

          <td className="w-150">
            <div className="mb-2">{el.titre ? el.titre : null}</div>
          </td>
          <td className="w-150">
            <div
              className="width-right-cell"
              onClick={() => handleTrainerClick(el)}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }} // Style optionnel
            >
              {el.user ? el.user : null}
            </div>
          </td>
          <td className="w-150">
            <div className="width-right-cell">
              <label className={"text-tag "}>{el.typeName}</label>
            </div>
          </td>
          <td className="w-150">
            <div className="mb-2">
              {el.expertiseName ? el.expertiseName : null}
            </div>
          </td>
          <td className="w-150">
            <div className="mb-2">
              <p>{el.place ? el.place : null}</p>
            </div>
          </td>
          <td className="w-90">
            <div className="mb-2">
              <p>{el.Mode ? el.Mode : null}</p>
            </div>
          </td>
          <td className="w-90">
            <div className="mb-2">
              <label className={"text-tag "}>{el.nbrParticipants}</label>
            </div>
          </td>
          <td className="w-250">
            <div className="mb-2">
              <label className={"text-tag "}>
                {stripHTMLTags(el.description)}
              </label>
            </div>
          </td>
          <td className="w-150">
            <Row className="text-align-last-right">
              {/* Delete | Edit Mission */}
              {history.location.pathname === "/user/formationsproposes" ? (
                <>
                  <Col className="flex-formations" lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      title="Modifier" // Tooltip for the first icon
                      onClick={() => {
                        onSelectItem("Edit", el);
                      }}
                    >
                      <FaPen className="mb-1 wh-20" />
                    </span>
                  </Col>
                  <Col className="flex-formations" lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      title="Supprimer" // Tooltip for the second icon
                      onClick={() => {
                        onSelectItem("Delete", el);
                      }}
                    >
                      <FaTrashAlt className="mb-1 wh-20" />
                    </span>
                  </Col>
                </>
              ) : history.location.pathname === "/admin/formationsproposes" ? (
                <>
                  <Col className="flex-formations" lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      title="Accepter" // Tooltip for the first icon
                      onClick={() => {
                        onSelectItemToChange("Confirm", el);
                        setModalType("Confirm");
                        setReponse(true);
                      }}
                    >
                      <TiTick className=" mb-1 wh-20" />
                    </span>
                  </Col>

                  <Col className="flex-formations" lg={6} md={12} xs={12}>
                    <span
                      className="btn-action grey pointer"
                      title="Refuser" // Tooltip for the second icon
                      onClick={() => {
                        onSelectItemToChange("Cancel", el);
                        setModalType("notConfirm");
                        setReponse(false);
                      }}
                    >
                      <MdCancel className="mb-1 wh-20" />
                    </span>
                  </Col>
                </>
              ) : (
                <Col className="flex-formations" lg={6} md={12} xs={12}>
                  <span
                    className="btn-action grey pointer"
                    title="Contactez-nous !" // Tooltip for the first icon
                    onClick={() => {
                      history.push("/contact");
                    }}
                  >
                    <FiPhoneCall className=" mb-1 wh-20" />
                  </span>
                </Col>
              )}
            </Row>
          </td>
          <td className="w-90">
            <div className="mb-2">
              <p>{el.active ? "Active" : "Non active"}</p>
            </div>
          </td>
        </tr>
      );
    });
  }

  /* Select item */
  function onSelectItem(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    if (type !== "Edit") setShow(true);
    else setModalShow(true);
  }
  /* change status */
  function onSelectItemToChange(type, mission) {
    setMode(type);
    setSelectedItem(mission);
    if (type !== "Edit") setShow(true);
    else setModalShow(true);
  }

  /* Delete | Interrupt Mission */
  /* Delete | Interrupt Mission */
  function confirmAction() {
    if (mode === "Confirm" || mode === "Cancel") {
      confirmMission(selectedItem.id, reponsedddd);
    } else if (mode === "Delete") {
      deleteMission(selectedItem.id);
    }

    // Refresh the page after the action
    window.location.reload();
  }

  /* Tabulation onChange */
  function changeTabulation(tab) {
    setResetForm(true);
    if (allMissions[tab] && allMissions[tab].length)
      setDisplayedData(allMissions[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }

  const [reponsedddd, setReponse] = useState();
  useEffect(() => {
    console.log("reponse neeeeeeeeeeeeeeed", reponsedddd);
  }, [reponsedddd]);

  const handleTrue = () => {
    setReponse(true);
  };

  const handleFalse = () => {
    setReponse(false);
  };

  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      <AlertModal
        show={showedMode}
        setShow={setShowedMode}
        content={"Mission éditée avec succès"}
      />
      <AlertModal
        show={showed}
        setShow={setShowed}
        content={"Mission ajoutée avec succès"}
      />
      {/* Confirm Modal */}

      <ModalConfirm
        show={show}
        setShow={setShow}
        message={
          "Vous êtes sûr de vouloir " +
          (mode === "Confirm"
            ? "accepter"
            : mode === "Cancel"
            ? "refuser"
            : "supprimer") +
          " cette formation ?"
        }
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
        setReponse={setReponse}
        modalType={modalType}
        handleTrue={handleTrue}
        handleFalse={handleFalse}
      />
      {/* Mission Modal */}

      <Modal
        show={showTrainerModal}
        onHide={() => setShowTrainerModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="border-0 text-black">
          <Modal.Title id="contained-modal-title-vcenter">
            Détails du formateur
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 text-black">
          {selectedTrainer && (
            <>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Nom et prénom :&nbsp;
                </span>
                {selectedTrainer?.user}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Email :
                </span>
                &nbsp;
                {selectedTrainer?.mail}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Téléphone :
                </span>
                &nbsp;
                {selectedTrainer?.phone}
              </p>
              <p>
                <span style={{ color: "#9900ff", fontWeight: "bold" }}>
                  Poste :
                </span>
                &nbsp;
                {selectedTrainer?.poste}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <ModalMission
        modalShow={modalShow}
        setModalShow={setModalShow}
        edit={selectedItem}
        setEdit={setSelectedItem}
        addMission={addMission}
        editMission={editMission}
      />
      {/* Mission Content */}
      <div id="header-Content" className="header-Content">
        {MissionCosmonauteHeader()}
      </div>
      <div id="main-without-header" className="main-without-header">
        {/* <Row className="align-md box-d-flex-order">
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <label className="title-page">Mes Missions et Formations</label>
          </Col>
          <Col lg={8} md={12} xs={12} className="w-md-100">
            <MissionsFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              defaultTabValue={defaultTabValue}
              allMissions={allMissions}
              setDisplayedData={setDisplayedData}
            />
          </Col>
          <Col lg={2} md={12} xs={12} className="w-md-100">
            <Button
              className="float-right save-button green shadow-unset"
              onClick={() => {
                setModalShow(true);
              }}
            >
              Nouvelle mission
            </Button>
          </Col>
        </Row> */}
        <Col lg={9} md={12} xs={12} className="w-md-100 filter-interview-page">
          <MissionsFilter
            resetForm={resetForm}
            setResetForm={setResetForm}
            defaultTabValue={defaultTabValue}
            allMissions={allMissions}
            setDisplayedData={setDisplayedData}
            postData={getAllProposedMissionsRecruiter}
            setFilters={setFilters}
          />
        </Col>
        <Tabs
          activeKey={defaultTabValue}
          onSelect={(k) => changeTabulation(k)}
          className="mt-3 mb-3 w-100"
        >
          <Tab
            eventKey="All"
            title={
              "Tous (" +
              (allMissions && allMissions.All ? allMissions.All.length : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>

          {/* <Tab
            eventKey="proposed"
            title={
              "Proposées (" +
              (allMissions && allMissions.proposed
                ? allMissions.proposed.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="ongoing"
            title={
              "En cours (" +
              (allMissions && allMissions.ongoing
                ? allMissions.ongoing.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="finished"
            title={
              "Terminées (" +
              (allMissions && allMissions.finished
                ? allMissions.finished.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab>
          <Tab
            eventKey="interrupted"
            title={
              "Interrompues (" +
              (allMissions && allMissions.interrupted
                ? allMissions.interrupted.length
                : 0) +
              ")"
            }
          >
            {showMissionsContent()}
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Helper function pour tracker les événements
export const trackPixelEvent = (eventName, data = null) => {
  if (window.fbq) {
    if (data) {
      window.fbq('track', eventName, data);
    } else {
      window.fbq('track', eventName);
    }
  }
};

const MetaPixel = () => {
    useEffect(() => {
        const loadScript = () => {
            if (window.fbq) return;
            
            const n = (window.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            });
            if (!window._fbq) window._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];

            const t = document.createElement('script');
            t.async = true;
            t.src = 'https://connect.facebook.net/en_US/fbevents.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(t, s);
        };

        loadScript();

        const initFbq = () => {
            if (typeof window.fbq !== 'undefined') {
                window.fbq('init', '1015217526632025');
                window.fbq('track', 'PageView');
                // Track ViewContent event on initial load
                window.fbq('track', 'ViewContent');
            } else {
                console.error("fbq is not defined");
            }
        };

        const scriptLoadInterval = setInterval(() => {
            if (typeof window.fbq !== 'undefined') {
                initFbq();
                clearInterval(scriptLoadInterval);
            }
        }, 100);

        return () => clearInterval(scriptLoadInterval);
    }, []);

    return (
        <Helmet>
            <noscript
                dangerouslySetInnerHTML={{
                    __html:
                        '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1015217526632025&ev=PageView&noscript=1" alt="" />',
                }}
            />
        </Helmet>
    );
};

export default MetaPixel;
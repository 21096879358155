import React from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/Login/LoginForm";
import Header from "../../components/Header/Header";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Instagram, Facebook, Youtube } from "lucide-react";
import Astro from "../../assets/img/6.png"
import login from "../../assets/img/1.png"

import './Login.scss'
import { Link } from "react-router-dom";


export default function Login() {
    const { t } = useTranslation();


    return (
        <div className="loginPage ">
             <Header />
            <div className="LoginSection ">
               
                <div className="container-fluid mt-5 ">
                    <div className="d-lg-flex flex-lg-row flex-md-wrap flex-sm-wrap flex-sm-column  mt-5 pl-3">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6  d-flex flex-column  align-items-center mt-5">
                            <div className="col-md-12 col-lg-10 col-xl-10 col-sm-12  text-justify   mt-4">
                                <p className="astro-text  roboto-meduim text-uppercase yellow-color">{t('login.h1')}</p>
                            </div>
                            <div className="col-md-10 col-lg-8 col-xl-8 col-sm-10  text-justify d-flex flex-column  align-items-center mb-4 mt-1">
                                <p className="it-Description fontSize-25 roboto-light">{t('login.h2')}</p>
                                {/* <Image src={login} alt="loginAstro-img1" height="auto" width="auto" className=" d-none d-lg-block login" /> */}

                            </div>
                            <div className="col-xxl-10 col-xl-10 mt-2">
                                {/* <Image src={Astro} alt="loginAstro-img" height="auto" width="auto" className=" d-none d-lg-block widthimg" /> */}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5 col-xl-6 col-xxl-6 col-sm-12  mt-sm-3 mb-md-5 p-0 ">
                            <div className="col-md-12 col-lg-12 col-xl-8 col-xxl-8 col-sm-12  mt-sm-3 mb-md-5 ">
                                <LoginForm />

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            {/* <div className="overlay-login"></div> */}
            <Footer />
        </div>
        
    );
}
function Footer() {
  const iconStyle = {
    color: '#FFD700',
    size: 24
  };

  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Acropolia Talents</h4>
            
            <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a 
                href="https://www.instagram.com/acropolia_academy/" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Instagram color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=61551011631802" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Facebook color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.youtube.com/@acropolia_academy" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Youtube color={iconStyle.color} size={iconStyle.size} />
              </a>
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Instagram, Facebook, Youtube } from "lucide-react";
import ContactFormateur from "../../components/contact/ContactInspirnaute";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "./Contact.scss";
import {Link} from "react-router-dom";
import img10 from "../../assets/img/Colonnes copie (2) (1).png";

export default function Contact() {
  const { t } = useTranslation();
  
  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <section className="contact-section1">
        <Header></Header>
        <div className="text-transparent text-uppercase text-center roboto-black fontSize-140 text-spacing-4 padding-top-title">
          
        </div>
        <div className="text-uppercase text-center roboto-black fontSize-140-m yellow-color mtb-70">
          FAITES-VOUS RAPPELER RAPIDEMENT !
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Vous souhaitez en savoir plus sur notre communauté de talents ?
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          Notre équipe prendra le temps d'identifier vos besoins
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          pour vous orienter vers la solution la plus optimale.
        </div>
        <img src={img10} alt="img10" className="img10" />
      </section>

      <section className="contact-section2">
        <div className="overlay-contact"></div>
        <div className="overlay-plane"></div>
        <ContactFormateur></ContactFormateur>
      </section>
     
      <Footer />
    </div>
  );
}


function Footer() {
  const iconStyle = {
    color: '#FFD700',
    size: 24
  };

  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Acropolia Talents</h4>
            
            <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a 
                href="https://www.instagram.com/acropolia_academy/" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Instagram color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=61551011631802" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Facebook color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.youtube.com/@acropolia_academy" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Youtube color={iconStyle.color} size={iconStyle.size} />
              </a>
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
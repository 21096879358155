import { Row, Col, Form } from "react-bootstrap";
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";
import { useFormik } from "formik";
import { useEffect } from "react";
export default function InspirnauteFilter({
  resetForm,
  setResetForm,
  defaultTabValue,
  allMissions,
  setDisplayedData,
}) {
  /* Form Filter */
  const formFilter = useFormik({
    initialValues: {
      name: "",
      place: "",
      email: "",
      experience: ""
    },
    onSubmit: (values) => {
      const filterData = allMissions ? allMissions : [];
      const list = filterData.filter((el) => filterCondition(values, el));
      setDisplayedData(list);
    },
  });
  /* reset Form */
  useEffect(() => {
    if (resetForm) {
      formFilter.resetForm();
      setResetForm(false);
    }
  }, [resetForm, setResetForm, formFilter]);
  /* All Conditions Filter */
  function filterCondition(FormValues, mission) {
    return (
      (FormValues.email === "" ||
        (FormValues.email !== "" &&
          mission.email?.toLowerCase()?.indexOf(FormValues.email) !== -1)) &&
      (FormValues.name === "" ||
        (FormValues.name !== "" &&
          mission.name?.toLowerCase()?.indexOf(FormValues.name) !== -1)) &&
      (FormValues.place === "" ||
        (FormValues.place !== "" &&
          mission.country?.toLowerCase()?.indexOf(FormValues.place) !== -1))
          && (FormValues.experience === "" ||
          (FormValues.experience !== "" &&
            mission.nbr_annee_exp?.toLowerCase()?.indexOf(FormValues.experience) !== -1))
    );
  }
  return (
    <>
      <Form onChange={formFilter.handleSubmit}>
        <Row>
          {/* <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <FaSearch className="position-absolute searsh-Input" />
              <Form.Control
                className="input-filter"
                type="text"
                placeholder="Experience"
                name="experience"
                id="experience"
                onChange={formFilter.handleChange}
                value={formFilter.values.experience.toLowerCase()}
                onBlur={formFilter.handleBlur}
              />
            </Form.Group>
          </Col> */}
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <FaSearch className="position-absolute searsh-Input" />
              <Form.Control
                className="input-filter"
                type="text"
                placeholder="Nom"
                name="name"
                id="name"
                onChange={formFilter.handleChange}
                value={formFilter.values.name.toLowerCase()}
                onBlur={formFilter.handleBlur}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <FaSearch className="position-absolute searsh-Input" />
              <Form.Control
                className="input-filter"
                type="text"
                placeholder="Mail"
                name="email"
                id="email"
                onChange={formFilter.handleChange}
                value={formFilter.values.email.toLowerCase()}
                onBlur={formFilter.handleBlur}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <FaMapMarkerAlt className="position-absolute searsh-Input" />
              <Form.Control
                className="input-filter"
                type="text"
                placeholder="Lieu"
                name="place"
                id="place"
                onChange={formFilter.handleChange}
                value={formFilter.values.place.toLowerCase()}
                onBlur={formFilter.handleBlur}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import img6 from "../../assets/img/6.png";
import planet2 from "../../assets/img/4 (1).png";
import Fusee from "../../assets/img/5.png";
import Fusee3 from "../../assets/img/6.png";
import circle from "../../assets/img/3 (1).png";
import { Instagram, Facebook, Youtube } from "lucide-react";
import { Row, Col, Button, Image, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./Home.scss";

export default function Home() {
  const { t } = useTranslation();
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
    window.scroll(0, 0);
  };

  return (
    <div className="homePage roboto-light">
      <Header />

      <section id="section1">
        <div className="hero-content">
          <h1 className="main-title">
            {t("home.slogan2")}
          </h1>
          <p className="it-Description">
            {t("home.description")}
          </p>
        </div>
      </section>

      <section id="section2" className="left-aligned-section">
        <div className="background-elements">
          <img
            src={img6}
            alt=""
            className="cristals-bg animate-6"
          />
          <img
            src={circle}
            alt=""
            className="bubble-bg animate-4"
          />
        </div>
        <div className="content-wrapper">
          <div className="text-content">
            <h2>PROPOSEZ</h2>
            <h2>DES FORMATIONS</h2>
            <h2>UNIQUES</h2>
            <h2>ET SUR MESURE</h2>
            <Button
              onClick={() => navigateTo('/inscription')}
              className="cta-button"
            >
              DEVENIR FORMATEUR
            </Button>
          </div>
        </div>
      </section>

      <section id="section4" className="split-section">
        <div className="background-elements">
          <img
            src={Fusee}
            alt=""
            className="fusee animate-4"
          />
          <img
            src={Fusee3}
            alt=""
            className="fusee3"
          />
        </div>
        <div className="cloud-section">
          <Row className="content-row">
            <Col lg={6} md={6} xs={12}>
              <div className="text-content left-aligned">
                <h2>DES FORMATIONS</h2>
                <h2>INÉDITES</h2>
                <p>Pour les entreprises, les particuliers, les formateurs etc...</p>
                <Button
                  onClick={() => navigateTo('/recruter')}
                  className="cta-button"
                >
                  TROUVER UN FORMATEUR
                </Button>
              </div>
            </Col>
            
          </Row>
        </div>
      </section>

      <Footer />
    </div>
  );
}

function Footer() {
  const iconStyle = {
    color: '#FFD700',
    size: 24
  };

  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Acropolia Talents</h4>
            
            <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a 
                href="https://www.instagram.com/acropolia_academy/" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Instagram color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=61551011631802" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Facebook color={iconStyle.color} size={iconStyle.size} />
              </a>
              <a 
                href="https://www.youtube.com/@acropolia_academy" 
                target="_blank" 
                rel="noreferrer" 
                className="social-icon"
              >
                <Youtube color={iconStyle.color} size={iconStyle.size} />
              </a>
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}